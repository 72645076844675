<div class="col s12 m6" [class.l3]="!large">
    <div class="card ">
        <div class="card-content {{ color }} white-text center ">
            <p class="card-stats-title ">
                <i class="material-icons tiny">{{ icon }}</i> {{ title }}
            </p>
            <h4 class="card-stats-number">{{ mainStat | numstring }}<span *ngIf="percent">%</span></h4>
            <div *ngIf="showGrowth">
                <p class="card-stats-compare " *ngIf="growthStat > 0"><i class="material-icons tiny icon-middle">keyboard_arrow_up</i>
                    <span class="{{color}}-text text-lighten-5" [ngPlural]="growthStat"> 
                            <ng-template ngPluralCase="=1">{{greaterThanText}}</ng-template>
                            <ng-template ngPluralCase="other"> {{greaterThanPluralText}}</ng-template>
                        </span>
                </p>
                <p class="card-stats-compare " *ngIf="0 > growthStat">
                    <span class="{{color}}-text text-lighten-5" [ngPlural]="growthStat">
                  <i class="material-icons tiny icon-middle">keyboard_arrow_down</i>
                  <ng-template ngPluralCase="=-1"> {{lessThanText}} </ng-template>       
                  <ng-template ngPluralCase="other"> {{ lessThanPluralText}}</ng-template>                               
                </span>
                </p>
                <p class="card-stats-compare " *ngIf="0 === growthStat">
                    <span class="{{color}}-text text-lighten-5"> {{ zeroText}} </span>
                </p>
            </div>
        </div>
    </div>
</div>