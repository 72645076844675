<div>
  <div class="section row" *ngIf="postCountLabels">
    <div class="col s12 l6">
      <div class="card">
        <div class="card-content white lighten-5">
          <div>
            <span class="card-title"> Front Page Posts Per Day</span>
            <div class="chart-wrapper">
              <canvas
                baseChart
                [datasets]="postCountData"
                [labels]="postCountLabels"
                [options]="chartOptions"
                chartType="bar"
                [colors]="['#ee6e73']"
                [legend]="false"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"
              ></canvas>
            </div>
            <div class="tiny">Over the past 7 days</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12 l6" *ngIf="hourPostCountData">
      <div class="card">
        <div class="card-content white lighten-5">
          <div>
            <span class="card-title">Best Time To Post</span>
            <div class="chart-wrapper">
              <canvas
                baseChart
                [datasets]="hourPostCountData"
                [labels]="hourPostCountLabels"
                [options]="hourChartOptions"
                [colors]="['#ee6e73']"
                chartType="horizontalBar"
                [legend]="false"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"
              ></canvas>
            </div>
            <div class="tiny">Adjusted to {{ timezoneCode }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
