<div *ngIf="posts.length > 1">
  <div class="divider"></div>
  <div class="section row" id="other-posts" >
    <h1>Other Posts By u/{{ posts[0].author }}</h1>
    <div class="white lighten-5">
      <div *ngFor="let post of posts" class="col s12 m6 l3">
          <div><a routerLink="/s/{{ post.id }}" title="{{ post.title }}" target="_blank" id="other-posts">
            <img *ngIf="post.images[0]" class="imgfit" [src]="post.images[0].src">
            <img *ngIf="!post.images[0]" class="imgfit" src="../assets/reddit_noimage.jpg">
            <div class="truncate">{{ (post.title.length>50)? (post.title | slice:0:100)+'..':(post.title) }}</div>
            <div id="other-posts-small">r/{{ post.subreddit_name }}</div>
            <div id="other-posts-small">Highest Rank Recorded: {{ post.max_rank }}</div>
          </a></div>
      </div>
    </div>
  </div>
</div>
