<div class="row center ">
    <input id="searchInput" type="text" (keyup)="search($event.target.value)" placeholder="Search by post ID, title or author" />
    <label for="searchInput" data-error="We've encounted an error while performing your search"> </label>



    <div class="preloader-wrapper small active" *ngIf="isLoading " [hidden]="searchError ">
        <div class="spinner-layer">
            <div class="circle-clipper left">
                <div class="circle"></div>
            </div>
            <div class="gap-patch">
                <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
                <div class="circle"></div>
            </div>
        </div>
    </div>
    <p *ngIf="searchError" class="error">There was an error performing your search</p>
</div>

<div *ngIf="filteredSubmissions">
    <ul class="collection borderless" [class.borderless]="(filteredSubmissions|async) === null|| (filteredSubmissions | async)?.length === 0">
        <li class="collection-item row avatar " *ngFor="let sub of filteredSubmissions | async ">
            <a (click)="goToDetailPage(sub.id)" style="cursor: pointer;" class="col s8 m8 l9">
                <img [src]="sub.thumbnail " (error)="handleImageError($event) " alt=" " class="circle ">
                <span class="title truncate trim" [innerHtml]="sub.title"></span>
                <p class="lighten-2 author">u/<span [innerHtml]="sub.author"></span></p>
            </a>
            <a [href]="sub.shortlink" target="_blank" class="secondary-content col s4 m4 l3 truncate shortlink"><i class="material-icons tiny open-new-window-icon">open_in_new</i> <span [innerHtml]="sub.shortlink"></span><i class="material-icons tiny new-icon" *ngIf="sub.current">new_releases</i></a>
        </li>
    </ul>
</div>