<div class="container center">

    <h1>Donate to Keep Stats Coming!</h1>
    <p class="condensed center">FrontPageStats was created as a passion project and a service to the Reddit community, however, running servers, maintaining bots, registering domain names and adding features costs time and money. We'll happily continue to provide this service free
        of charge and free of ads, but if you're willing to help us out with a donation, we'd REALLY appreciate it! </p>

      <div class="row">
        <donation-card header="Venmo" subheader="Scan the code or donate to @fpstats" address="https://venmo.com/code?user_id=3232521537978368444" qrcode="assets/venmo-code.png"></donation-card>
        <donation-card header="Patreon" subheader="Become a regular supporter " address="https://www.patreon.com/fpstats" qrcode="assets/Patreon-icon.png"></donation-card>
        <donation-card header="Paypal" subheader="Donate via credit card with Paypal" address="https://www.paypal.com/donate?hosted_button_id=7L2CCABF88YJG" qrcode="assets/paypal-qrcode.png" paypal></donation-card>
<!--        <div class="col s6">-->
<!--            <div class="card paypalcard">-->

<!--                &lt;!&ndash; PayPal Logo &ndash;&gt;-->
<!--                <div class="center">-->
<!--                    <a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="How PayPal Works" onclick="javascript:window.open('https://www.paypal.com/uk/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png" border="0" alt="PayPal Logo"></a>-->
<!--                    <p>Make a donation using a credit card or with your Paypal account. </p>-->
<!--                </div>-->
<!--                &lt;!&ndash; PayPal Logo &ndash;&gt;-->
<!--                <form #form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">-->
<!--                    <input type="hidden" name="cmd" value="_s-xclick">-->
<!--                    <input type="hidden" name="hosted_button_id" value="K6FVQCQV84Z5E">-->
<!--                    <input (click)="form.submit()" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">-->
<!--                    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">-->
<!--                </form>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</div>
