<div class="fixed-donate">
    <div class="donate">

        <div class="container center donate-text">
            This site costs money to run. Consider helping out by <a routerLink="donate">donating</a>
        </div>
    </div>
</div>
<app-nav></app-nav>
<router-outlet></router-outlet>
<app-footer></app-footer>