<footer class="page-footer">
    <div class="container">
        <div class="row">
            <div class="col l6 s12">
                <h5 class="white-text">Front Page Stats</h5>
                <p class="grey-text text-lighten-4">Front Page Stats is a free service that displays metrics over time for posts that make it to the front page of Reddit. This service is provided for free. Send any feedback, questions or issues to admin@frontpagestats.com or /u/fp_stats_bot</p>
            </div>
            <div class="col l4 offset-l2 s12">
                <h5 class="white-text">Links</h5>
                <ul>
                    <li><a class="grey-text text-lighten-3" routerLink="">Home</a></li>
                    <li><a class="grey-text text-lighten-3" routerLink="donate">Donate</a></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- <div class="container center"> -->
    <!-- <span class="grey-text text-lighten-4" href="#!"></span> -->

    <!-- </div> -->
    <div class="footer-copyright">
        <div class="container">
            © 2018 Copyright FrontPageStats.com
        </div>
    </div>
</footer>