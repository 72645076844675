<div class="container">
  <h1 class="header center">Front Page Stats for Reddit</h1>
  <div class="row center">
    <h5 class="header col s12 light">
      Look up what makes it to the front page of Reddit
    </h5>
  </div>

  <app-search></app-search>
  <meta-metrics></meta-metrics>
  <app-leaderboard></app-leaderboard>
</div>
