<!--<div class="section row" *ngIf="authorLabels && occurenceData">
    <div class="col s12 l6">
        <div class="card">
            <div class="card-content white lighten-5">
                <div>
                  <span class="card-title"> Most Front Page Posts by User</span>
                  <div class="chart-wrapper">
                    <canvas
                      baseChart
                      [datasets]="occurenceData"
                      [labels]="authorLabels"
                      [options]="chartOptions"
                      [colors]="['#ee6e73']"
                      chartType="horizontalBar"
                      [legend]="false"
                      (chartHover)="chartHovered($event)"
                      (chartClick)="chartClicked($event)"
                    ></canvas>
                  </div>
                  <div class="tiny">Since the beginning (July 2017)</div>
                </div>
            </div>
        </div>
    </div>-->
<div class="section row" id="leaderboard" >
    <div class="col s12 l12">
        <div class="card">
            <div class="card-content white lighten-5">

                <div class="row section">
                    <div class="col s12 m8 l9">
                        <span [class.loading]="isloading" [class.notloading]="!isloading">Loading ...</span>
                        <span class="card-title">Leader Board</span>
                        <table class="striped">
                                <tbody class="leaderboard">
                                <tr><th>Rank</th><th></th><th>{{ leaderTitle }}</th><th>Front Page Posts</th></tr>
                                <tr *ngFor="let leader of leaders; index as i">
                                    <td>{{i+1}}</td>
                                    <td><img *ngIf="leader.has_icon" src="{{ leader.icon_url }}" class="leaderimage"/></td>
                                    <td>
                                        <a href="https://www.reddit.com/{{ getLeaderUrl() }}/{{ leader.author }}" target="_blank">{{ leader.author }}</a>
                                    </td>
                                    <td>{{ leader.occurances }}</td>
                                </tr>
                                </tbody>
                        </table>
                    </div>
                    <div class="col s12 m4 l3" class="leaderboardfilter">
                        <span class="card-title">Leaders:</span>
                        <select (change)="changeLeaderClick($event.target.value)">
                            <option value='user'>Top Users</option>
                            <option value='domain'>Top Domains</option>
                            <option value='r'>Top Subreddits</option>
                        </select>
                        <span class="card-title">Filter By:</span>
                        <select (change)="timeFilterClick($event.target.value)">
                            <option value=0>All Data (since 7/17)</option>
                            <option value=1>Past Year</option>
                            <option value=2>YTD</option>
                            <option value=3>Last 30 Days</option>
                            <option value=4>Last 7 Days</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
