<div class="col s12 m4">
    <div class="card">
        <div class="card-image">
            <img class="donation-image" src="{{qrcode}}">
        </div>
        <div class="card-content">
            <span class="card-title">{{header}}</span>
            <p>{{subheader}} <span class="crypto-address"> </span></p>
            <div style="margin-top: 10px;">
            <a class="btn waves-effect waves-light red lighten-2" href="{{address}}">Donate!</a>
              </div>

        </div>
    </div>
    <div materialize="toast" [materializeActions]="clickActions"> </div>
</div>
