<nav>
    <div class="container">
        <div class="nav-wrapper">
            <a routerLink="" class="brand-logo">Front Page Stats</a>
            <ul id="nav" class="right hide-on-med-and-down">
                <li><a routerLink="">Home</a></li>
                <!--<li><a routerLink="" fragment="leaderboard">Leader Board</a></li>-->
                <li><a routerLink="donate">Donate</a></li>
            </ul>

        </div>
    </div>
</nav>
