<div class="col s12">
    <div id="index-banner" class="parallax-container" *ngIf="submission">
        <div class="parallax" materialize="parallax">
            <img *ngIf="submission.images[0]" class="imgfit" [src]="submission.images[0].src" alt="Unsplashed background img 1">
            <img *ngIf="!submission.images[0]" class="imgfit" src="assets/reddit_noimage.jpg" alt="Unsplashed background img 1">

        </div>
    </div>
    <div class="row">
        <div class="white" *ngIf="submission">
            <div class="row container">
                <h2 class="header">{{submission.title}}</h2>
                <p class="grey-text text-darken-3 lighten-3 flow-text">u/{{submission.author}} <span>on r/{{submission.subreddit_name}}</span></p>
                <a [href]="submission.shortlink">
                    <p *ngIf="submission.current"><i class="material-icons new-icon">new_releases</i> On The Front Page Now!</p>
                </a>
            </div>
        </div>
    </div>
</div>
<app-ad></app-ad>
<div class="container" *ngIf="submission">
    <div class="divider"></div>
    <div class="row section">
        <h1>Timeline of Events</h1>
        <stats-card title="Time from creation to post's first appearance on the front page" [mainStat]="toDurationString(submission.time_to_fp)" color="indigo" icon="comments" large="`true`" [showGrowth]="false"> </stats-card>
        <stats-card title="Amount of time the post was seen on the front page" [mainStat]="toDurationString(submission.time_on_fp)" color="amber" icon="comments" large="`true`" [showGrowth]="false"> </stats-card>
    </div>
    <div class="row section">

        <div *ngIf="timeline">
            <horizontal-timeline [timelineElements]="timeline" [showContent]="true"></horizontal-timeline>
        </div>
    </div>
    <ng-adsense></ng-adsense>

    <div class="divider"></div>
    <div class="row section" *ngIf="submission">
        <h1>Information and Stats </h1>
        <stats-card title="Max Number of Comments" [mainStat]="submission.max_comments" [growthStat]="commentGrowth" color="cyan" icon="comments" lessThanText="{{commentGrowth |absolute}}% decrease since first appearance" lessThanPluralText="{{commentGrowth |absolute}}% decrease since first appearance"
            greaterThanText="{{commentGrowth}}% increase since first appearance" greaterThanPluralText="{{commentGrowth}}% increase since first appearance" zeroText="No change since the first appearance">
        </stats-card>
        <stats-card title="Max Number of Upvotes" [mainStat]="submission.max_ups" [growthStat]="upvoteGrowth" color="pink" icon="thumb_down" lessThanText="{{upvoteGrowth |absolute}}% decrease since first appearance" lessThanPluralText="{{upvoteGrowth |absolute}}% decrease since first appearance"
            greaterThanText="{{upvoteGrowth}}% increase since first appearance" greaterThanPluralText="{{upvoteGrowth}}% increase since first appearance" zeroText="No change since the first appearance">
        </stats-card>

        <stats-card title="Maximum Upvote Ratio" [mainStat]="submission.max_ratio*100" [percent]="true" [growthStat]="ratioGrowth" color="grey" icon="thumbs_up_down" lessThanText="{{ratioGrowth |absolute}}% decrease since first appearance" lessThanPluralText="{{ratioGrowth |absolute}}% decrease since first appearance"
            greaterThanText="{{ratioGrowth}}% increase since first appearance" greaterThanPluralText="{{ratioGrowth}}% increase since first appearance" zeroText="No change since the first appearance">
        </stats-card>

        <stats-card title="Highest Rank Recorded" [mainStat]="submission.max_rank" [growthStat]="-rankGrowth" color="purple" icon="swap_vert" lessThanText="Lost {{rankGrowth |absolute}} spot since first appearance" lessThanPluralText="Lost {{rankGrowth | absolute}} spots since first appearance"
            greaterThanText="Up {{rankGrowth |absolute}} spot since first seen" greaterThanPluralText="Gained {{rankGrowth | absolute}} spots since first appearance" zeroText="No change since the first appearance">
        </stats-card>
    </div>

    <div class="row  section" *ngIf="submission">
        <div class="col s12">
            <div class="card">

                <div class="move-up blue-grey lighten-5">
                    <div>
                        <span class="chart-title blue-grey-text lighten-5">Metrics</span>
                        <div class="trending-line-chart-wrapper">
                            <canvas baseChart [datasets]="chartData" [labels]="chartLabels" [options]="chartOptions" [legend]="chartLegend" [chartType]="chartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
                            </canvas>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    </div>
    <div *ngIf="submission">
        <other-posts [author]="submission.author" [id]="submission.id"></other-posts>
    </div>
</div>
<!--<error-handler *ngIf="error" [error]="error"></error-handler>-->

<div id="page-loading" class="outer">
    <div class="row center middle">
        <div class="preloader-wrapper big active">
            <div class="spinner-layer">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div>
                <div class="gap-patch">
                    <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>
    </div>
</div>

